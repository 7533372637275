export default {
    getMessage(){
        console.log('Hey 🤡');
    },
    setCookie(name, value, days) {
        let date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        let expires = "; expires=" + date.toGMTString();
        document.cookie = name + "=" + value + expires;
    },
    getCookie(name) {
        var dc = document.cookie;
        var prefix = name + "=";
        var begin = dc.indexOf("; " + prefix);
        if (begin == -1) {
            begin = dc.indexOf(prefix);
            if (begin != 0) return null;
        }
        else
        {
            begin += 2;
            var end = document.cookie.indexOf(";", begin);
            if (end == -1) {
            end = dc.length;
            }
        }
        // because unescape has been deprecated, replaced with decodeURI
        //return unescape(dc.substring(begin + prefix.length, end));
        return decodeURI(dc.substring(begin + prefix.length, end));
    },
    isBase64(str) {
        try {
            return btoa(atob(str)) == str;
        } catch (err) {
            return false;
        }
    },
    getTextTranslate(text,lang ='en') {
        switch (lang) {
            case 'ar':
                if (text == 'This field is required.')
                    return 'هذه الخانة مطلوبه.';
                else if (text == 'Email and confirmation email must be the same.')
                    return 'يجب أن يكون البريد الإلكتروني والبريد الإلكتروني للتأكيد متماثلين.';
                else if (text == 'Please enter a valid URL, starting with \'https://\'')
                    return 'يُرجى إدخال عنوان URL صالح يبدأ بـ \'https://\'';
            case 'es':
                if (text == 'This field is required.')
                    return 'Este campo es requerido.';
                else if (text == 'Email and confirmation email must be the same.')
                    return 'El correo electrónico y el correo electrónico de confirmación deben ser iguales.';
                else if (text == 'Please enter a valid URL, starting with \'https://\'')
                    return 'Introduzca una URL válida que comience con \'https://\'';
                else if (text == 'All inputs are required.')
                    return 'Todos los campos son requeridos.';
                else if(text == 'Invalid email address.')
                    return 'Correo Electrónico Invalido.';
            case 'it':
                if (text == 'This field is required.')
                    return 'Questo campo è obbligatorio.';
                else if (text == 'Email and confirmation email must be the same.')
                    return "L'e-mail e l'e-mail di conferma devono coincidere.";
                else if (text == 'Please enter a valid URL, starting with \'https://\'')
                    return 'Inserite un URL valido che inizi con ‘https://’';
            case 'fr':
                if (text == 'This field is required.')
                    return 'Ce champ est requis.';
                else if (text == 'Email and confirmation email must be the same.')
                    return "L'e-mail et l'e-mail de confirmation doivent être identiques.";
                else if (text == 'Please enter a valid URL, starting with \'https://\'')
                    return 'Veuillez saisir une URL valide commençant par « https:// »';
            case 'de':
                if (text == 'This field is required.')
                    return 'Dieses Feld wird benötigt.';
                else if (text == 'Email and confirmation email must be the same.')
                    return "E-Mail und Bestätigungs-E-Mail müssen identisch sein.";
                else if (text == 'Please enter a valid URL, starting with \'https://\'')
                    return 'Bitte geben Sie eine gültige URL beginnend mit \'https://\' ein.';
            case 'ru':
                if (text == 'This field is required.')
                    return 'Это поле обязательно к заполнению.';
                else if (text == 'Email and confirmation email must be the same.')
                    return 'Электронная почта и электронное письмо с подтверждением должны совпадать.';
                else if (text == 'Please enter a valid URL, starting with \'https://\'')
                    return 'Введите действительный URL-адрес, который начинается с «https://»';
            case 'pt':
                if (text == 'This field is required.')
                    return 'Este campo é obrigatório.';
                else if (text == 'Email and confirmation email must be the same.')
                    return 'Email e email de confirmação devem ser os mesmos.';
                else if (text == 'Please enter a valid URL, starting with \'https://\'')
                    return 'Por favor, insira um URL válido.';
            case 'tr':
                if (text == 'This field is required.')
                    return 'Bu alan gereklidir.';
                else if (text == 'Email and confirmation email must be the same.')
                    return 'E-posta ve onay e-postası aynı olmalıdır.';
                else if (text == 'Please enter a valid URL, starting with \'https://\'')
                    return 'Lütfen \'https://\' ile başlayan geçerli bir URL girin.';
            case 'nl':
                if (text == 'This field is required.')
                    return 'Dit veld is verplicht.';
                else if (text == 'Email and confirmation email must be the same.')
                    return 'Dit e-mailadres staat al in ons archief.';
                else if (text == 'Please enter a valid URL, starting with \'https://\'')
                    return 'Voer een geldige URL in die begint met \'https://\'';
            case 'pl':
                if (text == 'This field is required.')
                    return 'To pole jest wymagan.';
                else if (text == 'Email and confirmation email must be the same.')
                    return 'Ten adres e-mail już figuruje w naszej bazie.';
                else if (text == 'Please enter a valid URL, starting with \'https://\'')
                    return 'Wprowadź prawidłowy adres URL rozpoczynający się od „https://”';
            case 'en':
                return text;
            default:
                return text;
        }
    },
    decorateUrlGA4(urlString){

        var generateLinkerParam = function(a) {
            // Function to properly grab ID's from Cookies
            var getCookiebyName = function(name) {
                var pair = document.cookie.match(new RegExp(name + '=([^;]+)'));
                return !!pair ? pair[1].match(/GA1\.[0-9]\.(.+)/)[1] : undefined;
            };
        
            // These are the 3 values used by the new linker
            var cookies = {
                _ga: getCookiebyName("_ga"),
                // Google Analytics GA ID
                _gac: undefined,
                // Google Remarketing
                _gid: getCookiebyName("_gid")// Google ID
            };
        
            // Calculate current browser_fingerprint based on UA, time, timezone and language
            // 
            var browser_fingerprint = (function(a, b) {
                var F = function(a) {
                    // Didnt check what this does, the algo just needs F to be defined. commenting out
                    Ne.set(a)
                };
                a = [window.navigator.userAgent, (new Date).getTimezoneOffset(), window.navigator.userLanguage || window.navigator.language, Math.floor((new Date).getTime() / 60 / 1E3) - (void 0 === b ? 0 : b), a].join("*");
                if (!(b = F)) {
                    b = Array(256);
                    for (var c = 0; 256 > c; c++) {
                        for (var d = c, e = 0; 8 > e; e++)
                            d = d & 1 ? d >>> 1 ^ 3988292384 : d >>> 1;
                        b[c] = d
                    }
                }
        
                F = b;
                b = 4294967295;
                for (c = 0; c < a.length; c++)
                    b = b >>> 8 ^ F[(b ^ a.charCodeAt(c)) & 255];
                return ((b ^ -1) >>> 0).toString(36);
            }
            )();
        
            // Function to hash the cookie value
            // The following functions takes a string and returns a hash value.
            var hash_cookie_value = function(val) {
                var A, C, D = function(a) {
                    A = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-_.";
                    C = {
                        "0": 52,
                        "1": 53,
                        "2": 54,
                        "3": 55,
                        "4": 56,
                        "5": 57,
                        "6": 58,
                        "7": 59,
                        "8": 60,
                        "9": 61,
                        "A": 0,
                        "B": 1,
                        "C": 2,
                        "D": 3,
                        "E": 4,
                        "F": 5,
                        "G": 6,
                        "H": 7,
                        "I": 8,
                        "J": 9,
                        "K": 10,
                        "L": 11,
                        "M": 12,
                        "N": 13,
                        "O": 14,
                        "P": 15,
                        "Q": 16,
                        "R": 17,
                        "S": 18,
                        "T": 19,
                        "U": 20,
                        "V": 21,
                        "W": 22,
                        "X": 23,
                        "Y": 24,
                        "Z": 25,
                        "a": 26,
                        "b": 27,
                        "c": 28,
                        "d": 29,
                        "e": 30,
                        "f": 31,
                        "g": 32,
                        "h": 33,
                        "i": 34,
                        "j": 35,
                        "k": 36,
                        "l": 37,
                        "m": 38,
                        "n": 39,
                        "o": 40,
                        "p": 41,
                        "q": 42,
                        "r": 43,
                        "s": 44,
                        "t": 45,
                        "u": 46,
                        "v": 47,
                        "w": 48,
                        "x": 49,
                        "y": 50,
                        "z": 51,
                        "-": 62,
                        "_": 63,
                        ".": 64
                    };
                    for (var b = [], c = 0; c < a.length; c += 3) {
                        var d = c + 1 < a.length
                          , e = c + 2 < a.length
                          , g = a.charCodeAt(c)
                          , f = d ? a.charCodeAt(c + 1) : 0
                          , h = e ? a.charCodeAt(c + 2) : 0
                          , p = g >> 2;
                        g = (g & 3) << 4 | f >> 4;
                        f = (f & 15) << 2 | h >> 6;
                        h &= 63;
                        e || (h = 64,
                        d || (f = 64));
                        b.push(A[p], A[g], A[f], A[h])
                    }
                    return b.join("")
                };
                return D(String(val));
            };
        
            // Now we have all the data Let's build the linker String! =)
            // First value is a fixed "1" value, the current GA code does the same. May change in a future
            return ["1", browser_fingerprint, "_ga", hash_cookie_value(cookies._ga), "_gid", hash_cookie_value(cookies._gid)].join('*');
        };

        var linkerParam = generateLinkerParam();

        var url = new URL(urlString);

        url.searchParams.append('_gl' , linkerParam);

        return url.toString();
    }
    
}