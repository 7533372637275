<template>
    <section class="popups" ref="popupsOverlay">
        <pop-styles></pop-styles>
        <section  class="popup-items" :class="'type-' + typeOfItem" v-for="(popup, typeOfItem) in checkedList" :key="uuid(popup)">
            <div v-for="(item) in popup" :key="item.id">
                <div :class="'popup-container'+(item.type==='Referal'?'':' is-done')" :data-time="item.settings.impressions.frequency" v-bind="popupFunctionality(item)" :id="'popup' + item.id"  v-if="item.version == 2 && (typeOfItem === 'popup' || typeOfItem === 'exitintent') && checkToShow(item.settings.impressions.device)">
                    <div class="wrap">
                        <a @click.prevent="PopUpClose($event,'popup' + item.id,item.settings.impressions.frequency)" class="close"><span>Close</span></a>
                        <div class="box" v-html="convertHtml(item.html)"></div>
                    </div>
                </div>
                <div class="popin-container with-delay" :data-delay=" item.settings.impressions.delay"  :id="'popin' + item.id" v-if="item.version == 2 && typeOfItem === 'popin' && checkToShow(item.settings.impressions.device)">
                    <div class="box" :class="popInClosed(item) ? 'to-hide':''" v-bind="popinArrow(item)" v-html="convertHtml(item.html)"></div>
                </div>
                <div :data-frequency="item.settings.impressions.frequency" class="alert-container" :data-text="item.functionality.alert_position" :id="'alert' + item.id" :class="item.functionality.alert_position === 0 ? 'no-fixed':'fixed-top'"  v-if="item.version == 2 && typeOfItem === 'alert' && checkToShow(item.settings.impressions.device)">
                    <div class="box" v-bind="headerFunctionality(item)" v-html="convertHtml(item.html)"></div>
                </div>
            </div>
            <popup-styles v-if="typeOfItem == 'popup' || typeOfItem == 'exitintent'"></popup-styles>
            <popin-styles :left="popInLeft" :distance="popInDistance" v-if="typeOfItem == 'popin'"></popin-styles>
            <alert-styles v-if="typeOfItem == 'alert'"></alert-styles>
        </section>
    </section>
</template>
<script>

import ApiTambo from '../../services/api/ApiTambo'
import Helpers from '../../services/lib/helpers'

import popStyles from './components/popStyles.vue'
import popupStyles from './components/popupStyles.vue'
import popinStyles from './components/popinStyles.vue'
import alertStyles from './components/alertStyles.vue'

export default {
    name: "Popups",
    components:{
        popStyles,
        popupStyles,
        popinStyles,
        alertStyles
    },
    props:{
        site_id : {
            type: String,
            default:'651'
        },
        site_lang : {
            type: String,
            default: 'en'
        },
        is_mobile : {
            type:String,
            default: ''
        },
        filters:{
            type:String,
            default:''
        },
        trackingtype : {
            type:Number,
            default: 0
        },
        brand:{
            type: String,
            default:'symphony'
        },
        is_live:{
            type: Boolean,
            default: false
        }
    },
    data(){
        return {
            popup : {},
            popups : [],
            cookie : false,
            checkPopin : false,
            checkAlert : false,
            checkPopUp : false,
            isMobile: false,
            setArrow :false,
            trackAssigned: false,
            itemsCheck : [],
            endDate:'',
            timerChecked : false ,
            countItem : 0,
            formTracking:{}
        }
    },
    beforeMount(){
        this.getApi();
    },
    destroy () {
        clearInterval(this.timer)
    },
    updated(){
        let self = this;

        if(this.$refs.popupsOverlay.getElementsByClassName('signup-module').length > 0){
            this.setFormFunctionality();    
        }
        if(this.$refs.popupsOverlay.getElementsByClassName('timer-container').length > 0){
            if(!this.timerChecked){
                this.endDate  = this.$refs.popupsOverlay.getElementsByClassName('timer-container')[0].getAttribute('data-time');
              
                this.tick();
                this.timer = setInterval(this.tick.bind(this), 1000);
                this.timerChecked = true
            }
        }
        if(this.$refs.popupsOverlay.getElementsByClassName('with-delay').length > 0){
            this.$refs.popupsOverlay.querySelectorAll('.with-delay').forEach(delay => {
                let delayTime = parseFloat(delay.getAttribute('data-delay'));
               
                setTimeout(() => {
                    if(delay.classList.contains('with-delay')){
                        delay.classList.remove('with-delay')
                    }
                }, delayTime);
            });
        }
        //popin updated function
        
        if( this.$refs.popupsOverlay.getElementsByClassName('popin-cta').length > 0){
            if(this.setArrow == false){
                let allCtas = this.$refs.popupsOverlay.getElementsByClassName('popin-cta');
                for (let cta of allCtas) {

                    if(cta.classList.contains('cta')){
                        let ctaClose = document.createElement('a');
                        ctaClose.classList.add('cta-close');

                        ctaClose.addEventListener('click', function(){
                            this.parentElement.parentElement.parentElement.classList.add('to-hide')
                        });

                        cta.previousElementSibling.classList.add('with-image');
                        cta.previousElementSibling.appendChild(ctaClose);
 
                    }
                    
                    cta.addEventListener('click', function(){
                    
                        if(this.parentElement.parentElement.classList.contains('to-hide')){
                            this.parentElement.parentElement.classList.remove('to-hide');
                          
                        } else {
                            this.parentElement.parentElement.classList.add('to-hide')
                        }
                    });
                }
                this.setArrow = true;
            }
            
        }
        
        if(this.$refs.popupsOverlay.getElementsByClassName('type-alert').length > 0){
            let alertTarget = '';
            /*Add class to the body*/
            if(!document.getElementsByTagName('body')[0].classList.contains('alert_open'))
                document.getElementsByTagName('body')[0].classList.add('alert_open');
            
            let topDistance = (this.$refs.popupsOverlay.getElementsByClassName('type-alert')[0].getElementsByClassName('alert-container').length>0) ?
                                this.$refs.popupsOverlay.getElementsByClassName('type-alert')[0].getElementsByClassName('alert-container')[0].clientHeight: 10;
        
            
            let checkTypes = this.$refs.popupsOverlay.getElementsByClassName('alerts-container');
            for(let i = 0; i < checkTypes.length; i++){
                if(checkTypes[i].classList.contains('alert') && checkTypes[i].getAttribute('id') !== null){
                    alertTarget = checkTypes[i].getAttribute('id').split('target')[1];
                }
            }
            
            
           if(alertTarget == 'header'){
                document.getElementsByTagName(alertTarget)[0].setAttribute('style','top:' + topDistance + 'px !important');  
            } else if(alertTarget!=='') {
                document.getElementsByTagName(alertTarget)[0].setAttribute('style','margin-top:' + topDistance + 'px !important');  
                document.getElementsByTagName('header')[0].setAttribute('style','top:' + topDistance + 'px !important');  
            }
            
            
            let moveHeaderInScroll = function(){
               
                let st = window.pageYOffset || document.documentElement.scrollTop;
                if(st > 60){
                    if(!document.getElementsByTagName(alertTarget)[0].classList.contains('checked')){
                        document.getElementsByTagName(alertTarget)[0].classList.add('checked');
                         
                        
                        if(!self.$refs.popupsOverlay.getElementsByClassName('alert-container')[0].classList.contains('fixed-top')){
                            self.$refs.popupsOverlay.getElementsByClassName('alert-container')[0].classList.add('moved');
                            document.getElementsByTagName(alertTarget)[0].setAttribute('style',''); 
                            document.getElementsByTagName('header')[0].setAttribute('style','');  
                        }
                        
                    }
                } else {
                    if(document.getElementsByTagName(alertTarget)[0].classList.contains('checked')){
                        if(alertTarget == 'header'){
                            document.getElementsByTagName(alertTarget)[0].setAttribute('style','top:' + topDistance + 'px !important');  
                        } else {
                            document.getElementsByTagName(alertTarget)[0].setAttribute('style','margin-top:' + topDistance + 'px !important');  
                            document.getElementsByTagName('header')[0].setAttribute('style','top:' + topDistance + 'px !important');  
                        }
                        
                        document.getElementsByTagName(alertTarget)[0].classList.remove('checked');
                        self.$refs.popupsOverlay.getElementsByClassName('alert-container')[0].classList.remove('moved');
                    }
                }
            }

            if(this.$refs.popupsOverlay.getElementsByClassName('type-alert')[0].getElementsByClassName('withclose').length > 0){
                let alertCloseBtn = this.$refs.popupsOverlay.getElementsByClassName('type-alert')[0].getElementsByClassName('withclose')[0];

                alertCloseBtn.addEventListener('click', function(){
                    
                    let parentId = this.parentElement.parentElement.getAttribute('id');
                    
                    let parentIdNumber = parentId.split('alert')[1];
                    
                    let frequency =  this.parentElement.parentElement.getAttribute('data-frequency') ? this.parentElement.parentElement.getAttribute('data-frequency') : 0;
                    
                    self.AlertClose(this,parentIdNumber,frequency);


                    document.getElementsByTagName('header')[0].setAttribute('style',''); 
                    if(self.$refs.popupsOverlay.getElementsByClassName('type-alert')[0].getElementsByClassName('no-fixed').length > 0 || self.isMobile){
                        document.removeEventListener('scroll',moveHeaderInScroll);
                    }
                })
            }

            //if It is not fixed on top:
            if(this.$refs.popupsOverlay.getElementsByClassName('type-alert')[0].getElementsByClassName('no-fixed').length > 0 || self.isMobile){
                document.addEventListener('scroll',moveHeaderInScroll);
            }          
        }

        //tracking
        if(this.$refs.popupsOverlay.getElementsByClassName('popup-submit').length > 0){
           

            if(!this.trackAssigned){
                

                //

                // Check each tracking button for each popin/alert
               let allEntries = Object.values(this.checkedList);
               let allBtns = [];
               for(const val of allEntries){
                    let eachItemLength = val.length;
                    for(let i=0; i < eachItemLength; i++){
                        if(val[i].children){
                            let reverse = atob(val[i].children);
                            let typeChildren =  JSON.parse(reverse); // decode the children
                            let allColumns = typeChildren[0].children;
                            for(let j = 0; j < allColumns.length; j++){
                                let columnChildren = allColumns[j].children;
                                for(let n = 0; n < columnChildren.length; n++){
                                    if(columnChildren[n].tagName == 'button' || columnChildren[n].tagName == 'signup'){
                                        
                                        allBtns.push(columnChildren[n].attributes.tracking);
                                        
                                    }
                                    if(columnChildren[n].tagName == 'signup'){
                                        self.formTracking = columnChildren[n].attributes.tracking;
                                    }
                                }
                            }
                        }
                    } 
                }
                
                
                let allTrackBtns = this.$refs.popupsOverlay.getElementsByClassName('popup-submit');
                let allTrackLength = allTrackBtns.length;
                
                for(let h = 0 ; h < allTrackLength; h++){
                    const currentUrl = allTrackBtns[h].getAttribute('href');
                    
                    if(currentUrl){
                        try{
                        if (self.is_live === true &&
                                ( (allTrackBtns[h].getAttribute('href').indexOf('https') > -1) &&  (allTrackBtns[h].getAttribute('href').indexOf(window.site_url) < 0))
                            ) {
                                allTrackBtns[h].setAttribute('href',Helpers.decorateUrlGA4(currentUrl));
                            }
                        }catch(error){
                            console.log(error);
                            allTrackBtns[h].setAttribute('href',encodeURIComponent(currentUrl));
                        }
                    }

                    allTrackBtns[h].addEventListener('click', function (e) {


                        let trackVariables = allBtns[h];
                        /** 
                         * Include data-title if doesn't exist into <a>
                        */
                        if (!allTrackBtns[h].hasAttribute('data-title')) {
                            allTrackBtns[h].setAttribute('data-title', trackVariables.label);
                        }
                        if (typeof window.dataLayer != 'undefined' && this.getAttribute('href')) {
                            window.dataLayer.push({ 'event': 'eventTracking', 'category': trackVariables.category.replace(/ /g, "_").toLowerCase(), 'action': trackVariables.action.replace(/ /g, "_").toLowerCase(), 'label': trackVariables.label });
                        }
                        if (this.getAttribute('href') && this.getAttribute('href').indexOf('#') == 0) { //close popup
                            
                            this.setAttribute('target', '');
                            let hashAtt = this.getAttribute('href');
                            let elementsAnchor = window.document.querySelectorAll('li a');
                            if (elementsAnchor.length > 0) {
                                window.document.querySelectorAll('li a').forEach(item => {
                                    if (item.getAttribute('href') === hashAtt) {
                                        document.location.href = hashAtt;
                                        item.click();
                                        return false;
                                    }
                                });
                            }

                            self.checkAndClose(this);
                            document.location.href = hashAtt;
                            e.preventDefault();
                        }

                    });
                }
                this.trackAssigned = true;
            }
            
        }
    },
    computed:{
        popInLeft(){
            if(this.checkedList['popin']){
                //let reverse = this.checkedList['popin'][0].children;
                let reverse = Helpers.isBase64(this.checkedList['popin'][0].children)? decodeURIComponent(escape(atob(this.checkedList['popin'][0].children))):atob(this.checkedList['popin'][0].children);
                let ChildrenObject = JSON.parse(reverse);
                return ChildrenObject[0].attributes.width;
            }else
                return {};
        },
        popInDistance(){
            if(this.checkedList['popin']){
                let reverse = Helpers.isBase64(this.checkedList['popin'][0].children)? decodeURIComponent(escape(atob(this.checkedList['popin'][0].children))):atob(this.checkedList['popin'][0].children);
                let ChildrenObject = JSON.parse(reverse);
                return ChildrenObject[0].attributes['location-distance'];
            }else
            return {};
        },
        checkedList(){
            let callback = v => v.length !== 0;
            if(Object.keys(this.popups).length < 5){
                return Object.fromEntries(Object.entries(this.popups).filter(([key, val]) => callback(val, key)));
             } else {
                //preview 
                let prevObject = {};
                if(this.popups.type == 'Alert'){
                    prevObject = {
                        'alert' : [this.popups]
                    };
                } else {
                    if(this.popups.type == 'Pop-In'){
                        prevObject = {
                            'popin' : [this.popups]
                        };
                    } else {
                        prevObject = {
                            'popup' : [this.popups]
                        };
                    }
                }
                
                return prevObject
            } 
        }
    },
    methods:{
        async getApi(){
            try {
                const response  =  await ApiTambo.getGeolocation(),
                      geoObj    = response.data,
                      newFilter = JSON.parse(this.filters);
                
                if(Object.keys(geoObj).length>0){
                    if(geoObj.country.iso_code){
                        newFilter.country = geoObj.country.iso_code;
                    }else if(geoObj.country){
                        newFilter.country = geoObj.country;
                    }else{
                        newFilter.country = null;
                    }
                    
                    if(geoObj.subdivisions !== undefined && geoObj.subdivisions[0].iso_code){
                        newFilter.state = geoObj.subdivisions[0].iso_code;    
                    }else{
                        newFilter.state = null;
                    }
                }else{
                    newFilter.country = null;
                    newFilter.state = null;
                }
                newFilter.httpReferer = document.referrer;
                const result  = await ApiTambo.getPopups(this.site_id,this.site_lang,(this.is_mobile==="1"),JSON.stringify(newFilter),this.brand);
                const data  = result.data.data;
                if(data.msg==='Success'){
                    this.popups = data.popups;
                 
                    this.checkDevice();
                }else{
                    console.log('Error',data.msg);
                }
            } catch (error) {
                console.log('Errr-> ',error);
            }
          
        },
        setFormFunctionality(){
            let self = this;
            let allForms = this.$refs.popupsOverlay.getElementsByClassName('signup-module');
            
            for (let form of allForms) {
                let formItems = form.getElementsByTagName('input');
                let thanksMessage = Helpers.getTextTranslate('Thank you for contacting us!',this.site_lang);

                for (let item of formItems) {

                    if(item.getAttribute('name') == 'thank_you_message'){
                        thanksMessage = item.value;
                    }

                    item.addEventListener('focus', function(e){
                        e.target.parentElement.classList.add('moved');
                        if(e.target.parentElement.getElementsByClassName('error-span').length>0)
                            e.target.parentElement.getElementsByClassName('error-span')[0].remove();
                    });

                    item.addEventListener('change', function(e){
                        switch (e.target.type) {
                            case 'email':
                                if(self.validateEmail(e.target.value)!=null && e.target.parentElement.getElementsByClassName('error-span').length>0){
                                    e.target.parentElement.getElementsByClassName('error-span')[0].remove();
                                }else{
                                    if(e.target.parentElement.getElementsByClassName('error-span').length==0){
                                        let span = document.createElement('span');
                                        span.innerHTML= Helpers.getTextTranslate('Invalid email address.',this.site_lang);
                                        span.classList.add('error-span');
                                        e.target.parentElement.insertBefore(span, e.target.nextSibling);
                                    }else{
                                        e.target.parentElement.getElementsByClassName('error-span')[0].innerHTML= Helpers.getTextTranslate('Invalid email address.',this.site_lang);
                                    }
                                }        
                            break;
                        }
                    });
                }

                let formSelects = form.getElementsByTagName('select');
                for (let item of formSelects) {
                    item.addEventListener('focus', function(e){
                        e.target.parentElement.classList.add('moved')
                    })
                }

                const formSignup = form.querySelector('form');
                let divMessage = form.querySelector('.message-form');

                

                if(formSignup.dataset.processed === undefined){
                    formSignup.addEventListener('submit', (e) => {
                        e.preventDefault();
                        
                        const   size = e.target.elements.length,
                                elements = e.target.elements;    
                        let params = new URLSearchParams(),
                            error  = false;
                        //params.append('lang',this.site_lang);
                        params.append('operation','processExitIntent');
                        params.append('popupid',formSignup.id.replace('signupId_',''));
                        // form validator
                        
                        if(!divMessage.classList.contains('hidden'))
                            divMessage.classList.add('hidden');
                        for(let i=0;i< size;i++){
                            elements[i].classList.remove('error');
                            const classList = [...elements[i].classList];
                            if(elements[i].name=="sendto" || elements[i].name=="subject" || elements[i].name=="cc" || elements[i].name=="cc[]" || elements[i].name=="emailcc[]"
                                || elements[i].name=="tr4pValidation" || elements[i].name=="tr4p" || elements[i].name=="source" || elements[i].name=="thank_you_message"
                            ){
                                if(elements[i].name=="tr4p"){
                                    params.append(elements[i].name,elements[i].value);
                                }else if((elements[i].name=="cc[]" || elements[i].name=="emailcc[]") && elements[i].value.trim()!=''){
                                    params.append("emailcc[]",elements[i].value);
                                }else if(elements[i].value.trim()!='' && elements[i].name!="thank_you_message"){
                                    params.append(elements[i].name,elements[i].value);
                                }
                            }else{
                                
                                if(classList.includes('required') && elements[i].value.trim().length==0){
                                    elements[i].parentNode.classList.add('error');
                                    let span = document.createElement('span');
                                    span.innerHTML= Helpers.getTextTranslate('This field is required.',this.site_lang);
                                    span.classList.add('error-span');
                                    if(elements[i].parentNode.getElementsByClassName('error-span').length== 0){
                                        elements[i].parentNode.insertBefore(span, elements[i].nextSibling);
                                    }
                                    error = true;
                                }else if(classList.includes('required') && elements[i].checked===false && (elements[i].id=='terms_label' || elements[i].id=='agree_label')){
                                    elements[i].parentNode.classList.add('error');
                                    let span = document.createElement('span');
                                    span.innerHTML= Helpers.getTextTranslate('This field is required.',this.site_lang);
                                    span.classList.add('error-span');
                                    if(elements[i].parentNode.getElementsByClassName('error-span').length== 0){
                                        elements[i].parentNode.insertBefore(span, elements[i].nextSibling);
                                    }
                                    error = true;
                                }else{
                                    if(elements[i].parentNode.getElementsByClassName('error-span').length> 0)
                                        elements[i].parentNode.getElementsByClassName('error-span')[0].remove();
                                    
                                    if(elements[i].type=='email' && self.validateEmail(elements[i].value)==null){
                                        error = true;
                                        elements[i].parentNode.classList.add('error');
                                        let span = document.createElement('span');
                                        span.innerHTML= Helpers.getTextTranslate('Invalid email address.',this.site_lang);
                                        span.classList.add('error-span');
                                        elements[i].parentNode.insertBefore(span, elements[i].nextSibling);
                                    }
                                    if(!error){
                                        params.append(elements[i].name,elements[i].value);
                                    }
                                        
                                        
                                }
                            }
                        }
                        if(error){
                            divMessage.innerHTML= Helpers.getTextTranslate('All inputs are required.',this.site_lang);
                            divMessage.classList.add('error');
                            divMessage.classList.remove('success');
                            setTimeout(()=>{divMessage.classList.remove('hidden');},1500);
                        }else{
                            ApiTambo.sendFormSignup(params).then(response=>{
                                const dataResult = response.data;
                                divMessage.classList.remove('hidden');
                                if(dataResult.error==true){
                                    divMessage.innerHTML= dataResult.msg;
                                    divMessage.classList.add('error');
                                    divMessage.classList.remove('success');
                                }else{
                                    divMessage.innerHTML= thanksMessage;
                                    formSignup.classList.add('hidden');
                                    divMessage.classList.remove('error');
                                    divMessage.classList.add('success');
                                    
                                    window.dataLayer.push({ 'event': 'eventTracking', 'category': self.formTracking.category.replace(/ /g, "_").toLowerCase(), 'action': self.formTracking.action.replace(/ /g, "_").toLowerCase(), 'label': self.formTracking.label });
                                }
                            });
                        } 
                    });
                    formSignup.dataset.processed = true;
                   
                }
                
            }
        },
        transformChildren(){
            let allEntries = Object.values(this.checkedList);
            for(const val of allEntries){
                let eachItemLength = val.length;
                for(let i=0; i < eachItemLength; i++){
                    let reverse = atob(val[i].children);
                    //let reverse = Helpers.isBase64(val[i].children)? decodeURIComponent(escape(atob(val[i].children))):atob(val[i].children);
                    val[i].children =  JSON.parse(reverse); // decode the children
                } 
            }
           
        },
        checkAndClose(item) {
            if(this.$refs.popupsOverlay.getElementsByClassName('popup-container')){
                let allPopups = this.$refs.popupsOverlay.getElementsByClassName('popup-container');
                for(let container of allPopups){
                    if(container.contains(item)){
                        let containerId = container.getAttribute('id');
                        let containerFrequency = container.getAttribute('data-time') ? container.getAttribute('data-time') : 0;
                        container.classList.add('is-done');
                        this.Close('popup', containerId,containerFrequency);
                    }
                }
            }
        },
        popupFunctionality(item){
            if(item.type==='Referal' && this.countItem==0){
                console.log('Referal: ',document.referrer);
                console.log('Item: ',item.id);
                this.countItem = -1;
            }
            if(this.itemsCheck.filter(e=>e.id===item.id).length==0 && (item.type === 'Pop-Up' || item.type === 'Exit-Intent')){ 
                //this.checkPopUp = true;

                let itemPageDelay   = item.functionality.page_delay,
                    itemDelay       = item.settings.impressions.delay,
                    itemId          = item.id,
                    self            = this,
                    pageCount       = 0,
                    targetPop       = this.$refs.popupsOverlay.getElementsByClassName('popup-container'),
                    hasPageFilter   = item.hasPageFilter;

                let urlSite = window.location.href;

                //Cookies! 🍪
                let setCookie = 'popup-cookie_'+ itemId; 
                let checkCookie = Helpers.getCookie(setCookie);
                this.cookie = (checkCookie === '1') ? true : false;
                let daysFormat = [0,1,7,30];
                let frequencyDays = item.settings.impressions.frequency !== 4 ? daysFormat[parseInt(item.settings.impressions.frequency)]  : null;
                this.itemsCheck.push({id:itemId,cookie:this.cookie})
                
                
                let mySession = window.sessionStorage;
                
                if(!itemPageDelay){  //check if we are getting empty value
                    itemPageDelay = 0;
                }

                //  Exit intent events
                let loadFunction = function (){
                    if(!mySession.getItem("pageCount")){
                        pageCount = (hasPageFilter)?parseInt(itemPageDelay)+1:1;
                    } else {
                        pageCount = mySession.getItem("pageCount");
                        pageCount = parseInt(pageCount) + 1;
                    }
                    mySession.setItem("pageCount", pageCount);
                    if(parseInt(pageCount) >= (parseInt(itemPageDelay))){
                        document.addEventListener('mouseout',exitMouse);
                    }
                }
                let exitMouse = function (event){
                    if(urlSite.indexOf('preview=') > -1){
                        for(const pop  of targetPop ){
                            if(pop.id===`popup${itemId}`)
                                pop.classList.remove('is-done');
                        }
                         //remove events
                        window.removeEventListener('load',loadFunction);
                        document.removeEventListener('mouseout',exitMouse)

                    } else {
                        if (!event.toElement && !event.relatedTarget) {
                            setTimeout(() => {
                                for(const pop  of targetPop ){
                                    if(pop.id===`popup${itemId}`)
                                        pop.classList.remove('is-done');
                                }
                                

                                //no duplicate impression from preview
                                
                                if(urlSite.indexOf('preview=') < 0){
                                    //add impression 
                                    if(checkCookie == null ){
                                        self.impression(itemId);
                                        if(item.settings.impressions.frequency !== 4){ //always not create 🍪
                                            let daysArray = [365,1,7,30];
                                            Helpers.setCookie(setCookie, 1,daysArray[frequencyDays]);
                                        
                                        }
                                        
                                        //remove events
                                        window.removeEventListener('load',loadFunction);
                                        document.removeEventListener('mouseout',exitMouse)
                                    }
                                    
                                }
                            }, itemDelay);   
                        }
                    }
                    
                }

                

                if(item.type == 'Pop-Up'){
                    
                    setTimeout(() => {
                        
                        if(this.itemsCheck.some((e)=>{ return e.id===itemId && e.cookie===false})){
                            for(const pop  of targetPop ){
                                if(pop.id===`popup${itemId}`)
                                    pop.classList.remove('is-done');
                            }
                        }
                        
                        //no duplicate impression from preview
                        
                        if(urlSite.indexOf('preview=') < 0 || !this.itemsCheck.some((e)=>{ return e.id===itemId && e.cookie===false}) ){
                            //add impression 
                            if(checkCookie == null){
                                self.impression(itemId);
                                if(item.settings.impressions.frequency !== 4){ //always not create 🍪
                                    Helpers.setCookie(setCookie, 1,frequencyDays);
                                }
                                
                            }
                        }
                    }, itemDelay); 

                } else {  //is exit intent
                    if(urlSite.indexOf('preview=') >= 0){
                        loadFunction();
                        document.addEventListener('mouseout',exitMouse);
                    } else {
                        if(checkCookie == null && this.itemsCheck.some((e)=>{ return e.id===itemId && e.cookie===false})){
                            loadFunction();
                        } 
                    }
                }
               
            }
            

        },

        headerFunctionality(item){
            
            let urlSite = window.location.href;
            if(urlSite.indexOf('preview=') < 0 ){
                //add impression 
                if(this.checkAlert == false){
                    this.impression(item.id);
                    this.checkAlert = true;
                   
                }
            }
        },
        popinArrow(item){
           
                
            //check if is preview 
            let urlSite = window.location.href;
            if(urlSite.indexOf('preview=') < 0 ){
                //add impression 
                if(this.checkPopin == false){
                    this.checkPopin = true;
                    this.impression(item.id);
                    
                }
            }
            if(item.functionality.close_outside == 1){
                //this.closeScroll();
            }
        },
      
        closeScroll(){

            let self = this; 
            let popinScroll = function(){
                let targetPopin = self.$refs.popupsOverlay.getElementsByClassName('popin-container')[0].getElementsByClassName('box')[0];
                if(!targetPopin.classList.contains('to-hide')){
                    targetPopin.classList.add('to-hide')
                } 
            }
            document.addEventListener('scroll',popinScroll);
        },
        popInClosed(item){
            let finalResponse;
            if(!this.isMobile){
                let mySession = window.sessionStorage;
                let loadPopinFunction = function (){
                    mySession.setItem("popinCount", 1);
                }
                
                switch (item.functionality.start_closed) {
                    case 1: 
                        finalResponse = true;
                       
                    break;
                    case 2: 
                        finalResponse =  false;
                       
                    break;
                    case 3: 
                        if(!mySession.getItem("popinCount")){
                            finalResponse =  false;
                            window.addEventListener('load',loadPopinFunction);
                        } else {
                            finalResponse = true;
                            //window.removeEventListener('load',loadPopinFunction); 
                        } 
                         
                    break;
                }
            } else {
                finalResponse = true;
            }
            return finalResponse;
        },
        checkDevice() {
            let self = this;
            /* eslint-disable */
            (function (a) {
            if (
                /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
                a
                ) ||
                /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
                a.substr(0, 4)
                )
            )
            self.isMobile = true;
            })(navigator.userAgent || navigator.vendor || window.opera);
            /* eslint-enable */
        },
        Close(type,id,frequency){

            let nameCookie='';
            let separateId = id.split(type);

            
          

            if(type == 'popup'){
                nameCookie = 'popup-cookie_'+ separateId[1];
            } else {
                nameCookie = 'alert-cookie_'+ separateId[1];
            }

            

            let daysFormat = [365,1,7,30];
            let frequencyDays = frequency !== 4 ? daysFormat[frequency]  : null;

            //no duplicate impression from preview
            let urlSite = window.location.href;
            if(urlSite.indexOf('preview=') < 0){
                setTimeout(function(){
                    if(frequency !== 4){
                        this.cookie = true;
                        Helpers.setCookie(nameCookie, 1,frequencyDays);
                    }
                }, 600);
            }

            
        },
        PopUpClose(e, id,frequency){
            let parentPopup = e.target.parentElement.parentElement;
            parentPopup.classList.add('is-done'); // hide
            
            this.Close('popup', id,frequency);
        },
        AlertClose(e, id, frequency){
            let parentPopup = e.parentElement.parentElement;
            parentPopup.classList.add('is-done'); // hide
            let nameCookie = 'alert-cookie_'+ id;

            let daysFormat = [0,1,7,30];
            let frequencyDays = frequency !== 4 ? daysFormat[frequency]  : null;

            setTimeout(function(){
                this.cookie = true;
                Helpers.setCookie(nameCookie, 1,frequencyDays);
            }, 600);
            /**Remove class to body */
            if(document.getElementsByTagName('body')[0].classList.contains('alert_open')){
                document.getElementsByTagName('body')[0].classList.remove('alert_open');
                document.getElementsByTagName('body')[0].classList.add('alert_closed');
            }
            document.getElementsByTagName('body')[0].setAttribute('style',''); 
        },
        impression(popupId){
         
            try {
                ApiTambo.setPopupsImpression(popupId,this.brand,this.site_id);
               
            } catch (error) {
                console.log('Error: ',error);
            }
        },
        uuid(e) {
            if (e.uid) return e.uid;

            const key = Math.random()
                .toString(16)
                .slice(2);

            this.$set(e, "uid", key);
            
            return e.uid;
        },
        convertHtml(html){
            let reverse = Helpers.isBase64(html)? decodeURIComponent(escape(atob(html))):atob(html);
            return JSON.parse(reverse);
        },
        checkToShow(device){
        
            let response = true;
            switch (device){
                case 0:
                    response =  true 
                break;
                case 1:
                    if(this.isMobile){
                        response = false;
                    } else {
                        response = true;
                    }
                break;
                case 2:
                    if(this.isMobile){
                        response = true;
                    } else {
                        response = false;
                    }
                break;
            }
            return response;
        },
        validateEmail(email){
            return String(email).toLowerCase().match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
        },
        updateRemaining(distance) {
            this.$refs.popupsOverlay.getElementsByClassName('timer-day')[0].innerHTML = Math.floor(distance / (1000 * 60 * 60 * 24))
            this.$refs.popupsOverlay.getElementsByClassName('timer-hours')[0].innerHTML = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
            this.$refs.popupsOverlay.getElementsByClassName('timer-minutes')[0].innerHTML = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
            if(this.$refs.popupsOverlay.getElementsByClassName('timer-seconds').length > 0){
                this.$refs.popupsOverlay.getElementsByClassName('timer-seconds')[0].innerHTML = Math.floor((distance % (1000 * 60)) / 1000);
            }


        },
        tick() { 
            
            const distance = Math.max(this.endDate - new Date(), 0)
            this.updateRemaining(distance)
                
            if (distance === 0) {
                clearInterval(this.timer)
            }

        },
    }
}
// function onlyUnique(value, index, self) {
//   return self.indexOf(value) === index;
// }
</script>